<template>
  <div class="answerNotClear" >
    <el-row v-if="studentNo && studentName" class="answerHead">
      <el-col :span="24">
        <span>学号：{{studentNo}}</span>， <span>姓名：{{studentName}}</span>
        <span v-if="chooseType == 'SingleChoice'">， 单选题</span>
        <span v-if="chooseType == 'MultiChoice'">， 多选题</span>
        <span v-if="chooseType == 'Judge'">， 判断题</span>
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px;">
      <el-col :span="10" style="padding: 10px 0;">
        <el-image style="max-height: 450px; max-width: 450px; min-width: 250px;  "
                  :src="url" fit="contain" lazy></el-image>
      </el-col>
      <el-col :span="14">
          <el-row v-for="(item, index) in answerRule" style="margin-top: 22px;">
            <el-col :span="2" style="text-align: right; margin-top: 5px;">
              {{item.no}}
            </el-col>
            <el-col :span="22" style="text-align: left">

              <el-radio-group v-model="item.answer" size="small" v-if="item.type =='SingleChoice'">
                <el-radio-button :label="c" v-for=" c in item.chooseList" style="margin-left: 8px;"></el-radio-button>
              </el-radio-group>
              <el-checkbox-group v-model="item.answer" size="small" v-if="item.type =='MultiChoice'">
                <el-checkbox-button :label="c" v-for=" c in item.chooseList" :key="c" style="margin-left: 8px;"></el-checkbox-button>
              </el-checkbox-group>
              <el-radio-group v-model="item.answer" size="small" v-if="item.type =='Judge'">
                <el-radio-button :label="c" v-for=" c in item.chooseList" style="margin-left: 8px;"></el-radio-button>
              </el-radio-group>
            </el-col>

          </el-row>
      </el-col>
    </el-row>
    <el-row style="margin-top: 30px;">
      <el-col :span="24">
        <el-button type="primary" size="mini" @click='selectAnswer()'>填涂修改完成</el-button>
      </el-col>
    </el-row>

    <span style="color: #F6F7FC;">{{info.scan_file_id}}</span>
  </div>

</template>

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  import XEUtils from 'xe-utils'
  import * as simpleApi from "request/simple";
  import * as mUtils from '@/utils/mUtils'

  export default {
    name: 'answerNotClear',
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
    },
    props: {
      info: {
        type: Object
      }
    },
    components:{

    },
    watch: {
      info: {
        deep: true, immediate:true,
        handler(n, o){
          console.log(n)
          if(n){
            this.id = n.id;
            this.url = n.oss_cdn_name;
            this.examId = n.exam_id;
            this.examSeq = n.exam_seq;
            this.myAnswer = n.answer;
            this.studentId = n.student_id;
            this.studentNo = n.student_no;
            this.studentName = n.student_name;
            let examSeq = n.exam_seq;
            if(examSeq != null){
              try{
                let ja = JSON.parse(examSeq);
                let myAnswer = []
                try{
                  if(n.answer != null){
                    myAnswer = JSON.parse(n.answer);
                  }
                }catch (err) {console.log(err) }
                this.answerRule = [];
                for (let i=0; i<ja.length; i++){
                  let e = ja[i];
                  let anw = null;
                  if(myAnswer.length>i) anw = myAnswer[i];
                  let chooses = e.chooses;
                  chooses.push('未选');
                  let ar = {no: e.seq, type: e.type, chooseList: chooses, answer: null, };
                  this.chooseType = e.type;
                  if((ar.type == "SingleChoice" || ar.type == "Judge") && anw != null){
                    if(anw == "") ar.answer = "未选"; else ar.answer = anw;
                  }
                  if(ar.type == "MultiChoice" ) {
                    if (anw == null) {
                      ar.answer = []
                    } else {
                      if(typeof(anw) == "string" ) ar.answer = []
                      else ar.answer = anw;
                    }
                  }
                  this.answerRule.push(ar);

                }
                console.log('watch', this.answerRule);
              }catch (e) {
                console.log(e)
              }
            }
          }
        }
      },
    },
    data() {
      return {
        id: null, url: '', studentId: null, studentNo: null, studentName: null, examId: null, examSeq: null, myAnswer: null,
        theDialogVisible: true, loading:false, tableData: [], chooseType: null,
        answerRule: [{no: 46, type: "dx", chooseList: ['A', 'B', 'C', 'D', '未选'], newAnswer: 'C'},
          {no: 47, type: "dx", chooseList: ['A', 'B', 'C', 'D', '未选'], newAnswer: 'B'},
          {no: 48, type: "dx", chooseList: ['A', 'B', 'C', 'D', '未选'], newAnswer: null},
          {no: 49, type: "dx", chooseList: ['A', 'B', 'C', 'D', '未选'], newAnswer: 'A'},
          {no: 50, type: "dx", chooseList: ['A', 'B', 'C', 'D', '未选'], newAnswer: 'B'}],
        searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null},
      }
    },

    methods: {

      loadAnswer(){

      },
      setNewAnswer(index, a){
        let d = this.answerRule[index];
        let ar = XEUtils.find(d.newAnswer, item => {
          return item == a;
        });
        if(!ar){
          d.newAnswer.push(a);
        }
      },
      selectAnswer(){
        console.log("selectAnswer", this.answerRule);
        let anw = "";
        let anwArr = []
        for (let i=0; i<this.answerRule.length; i++){
          let a = this.answerRule[i];
          anwArr.push(a.answer);
        }

        let p = {id: this.id, status: '00', answer: JSON.stringify(anwArr)};
        let that = this;
        let param = { controllerName: 'exam/ocr', methodName: '/update', param: p};
        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
          if (code == 0) {
            that.$message({ type: 'success', message: '设置成功!'});
            that.$emit('ok');
          }
        });
      },

    }
  }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

  .answerNotClear {

    .answerHead {
      margin-bottom: 10px;
      font-weight: bold;
      color: blue;
      span {
        font-size: 24px;
      }
    }

  }




</style>
