<template>
    <div class="exceptPage">
        <el-row style="margin-top: 20px;">
            <el-col :span="18" style="padding-left: 10px;">
                <div class="left-part" :style="leftStyle">
                    <studentNoLost v-if="selStudentNoLost != null" :info="selStudentNoLost" @ok="nextStudentNoLost" ></studentNoLost>
<!--                    <examExcept v-if="selExamExcept != null" :info="selExamExcept"  @ok="nextExamExcept"></examExcept>-->
                    <answerNotClear v-else-if="selAnswerNotClear != null" @ok="nextAnswerExcept" :info="selAnswerNotClear"></answerNotClear>
                    <examImageEditor v-if="selExamExcept != null" :info="selExamExcept"  @ok="nextExamExcept"></examImageEditor>
                    <div v-else  style="width: 100%;">

                    </div>
                </div>

            </el-col>
            <el-col :span="6" style="padding-left: 10px; padding-right: 10px;">
                <div class="right-part">
                    <el-row>
                        <el-col :span="4">
                            考试：
                        </el-col>
                        <el-col :span="20">
                            <el-select v-model="examId" filterable placeholder="请选择" @change="examChange" style="min-width: 250px;">
                                <el-option v-for="item in examList" :key="item.id" :label="item.name+'('+ item.id+ ')'" :value="item.id"></el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                    <el-row v-if="examId != null" style="margin-top: 20px;">
                        <el-col :span="24">
                            <div @click="examChange">
                                <span style="color: blue">待识别：{{examCount.ocring}}张，异常：{{examCount.except}}张，已识别：{{examCount.ocred}}张，共{{examCount.ocring+examCount.except+examCount.ocred}}张</span>
                            </div>

                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px;">
                        <el-col :span="24">
                            <el-collapse v-model="activeName" accordion @change="collapseChange" >
                                <el-collapse-item name="1">
                                    <template slot="title">
                                        <span class="collapse-title">学号异常</span>
                                        <span v-if="loseNoList && loseNoList.length > 0" class="collapse-title" style="color: red;" >({{loseNoList.length}})</span>
                                    </template>
                                    <el-row class="data-row" v-if="loseNoList && loseNoList.length > 0" v-for="(item, index) in loseNoList">
                                        <el-col :span="15" :offset="1" class="student-no-lost">
                                            <el-link type="primary" @click="openStudentNoLost(item)">{{getExamName(index+1, item)}}</el-link>
                                        </el-col>
                                        <el-col :span="4" >
                                            <el-button type="primary"  size="mini" @click="onBottom(item)" >置底</el-button>
                                        </el-col>
                                        <el-col :span="4">
                                            <el-button type="danger" icon='edit' size="mini" @click="onDeleteScanFile(item)" >删除</el-button>
                                        </el-col>
                                    </el-row>

                                </el-collapse-item>
                                <el-collapse-item name="2">
                                    <template slot="title">
                                        <span class="collapse-title">学生信息丢失</span>
                                        <span v-if="loseNoY1List && loseNoY1List.length > 0" class="collapse-title" style="color: red;" >({{loseNoY1List.length}})</span>
                                    </template>
                                    <el-row class="data-row" v-if="loseNoY1List && loseNoY1List.length > 0" v-for="(item, index) in loseNoY1List">
                                        <el-col  :span="15" :offset="1" class="student-no-lost">
                                            <el-link type="primary" @click="openStudentNoLost(item)">{{getExamName(index+1, item)}}</el-link>
                                        </el-col>
                                        <el-col :span="4" >
                                            <el-button type="primary"  size="mini" @click="onBottom(item)" >置底</el-button>
                                        </el-col>
                                        <el-col :span="4">
                                            <el-button type="danger" icon='edit' size="mini" @click="onDeleteScanFile(item)" >删除</el-button>
                                        </el-col>
                                    </el-row>
                                </el-collapse-item>

                                <el-collapse-item name="4">
                                    <template slot="title">
                                        <span class="collapse-title">试卷类型异常</span>
                                        <span v-if="exceptList && exceptList.length > 0" class="collapse-title" style="color: red;" >({{exceptList.length}})</span>
                                    </template>
                                    <el-row class="data-row" v-if="exceptList && exceptList.length > 0" v-for="(item, index) in exceptList">
                                        <el-col :span="15" :offset="1" class="student-no-lost">
                                            <el-link type="primary" @click="openExamExcept(item)">{{getExceptName(index+1, item)}}</el-link>
                                        </el-col>

<!--                                        <el-col :span="6" v-if="item.status=='Y5' || item.status=='Y6' || item.status=='Y7'" >-->
<!--                                            <el-button type="primary"  size="mini" @click="downloadImage(item)" >下载</el-button>-->
<!--                                        </el-col>-->
                                        <el-col :span="4" >
                                            <el-button type="primary"  size="mini" @click="onBottom(item)" >置底</el-button>
                                        </el-col>
                                        <el-col :span="4">
                                            <el-button type="danger" icon='edit' size="mini" @click="onDeleteScanFile(item)" >删除</el-button>
                                        </el-col>
                                    </el-row>

                                </el-collapse-item>
                                <el-collapse-item name="5">
                                    <template slot="title">
                                        <span class="collapse-title">学号重复</span>
                                        <span v-if="duplicList && duplicList.length > 0" class="collapse-title" style="color: red;" >({{duplicList.length}})</span>
                                    </template>
                                    <el-row class="data-row" v-if="duplicList && duplicList.length > 0" v-for="(item, index) in duplicList">
                                        <el-col :span="15" :offset="1" class="student-no-lost">
                                            <el-link type="primary" @click="openStudentNoLost(item)">{{getExamName(index+1, item)}}</el-link>
                                        </el-col>
                                        <el-col :span="4" >
                                            <el-button type="primary"  size="mini" @click="onBottom(item)" >置底</el-button>
                                        </el-col>
                                        <el-col :span="4">
                                            <el-button type="danger" icon='edit' size="mini" @click="onDeleteScanFile(item)" >删除</el-button>
                                        </el-col>
                                    </el-row>

                                </el-collapse-item>
                                <el-collapse-item name="7">
                                    <template slot="title">
                                        <span class="collapse-title">第一页数据丢失</span>
                                        <span v-if="page1NoFoundList && page1NoFoundList.length > 0" class="collapse-title" style="color: red;" >({{page1NoFoundList.length}})</span>
                                    </template>
                                    <el-row class="data-row" v-if="page1NoFoundList && page1NoFoundList.length > 0" v-for="(item, index) in page1NoFoundList">
                                        <el-col :span="15" :offset="1" class="student-no-lost">
                                            <el-link type="primary" @click="openStudentNoLost(item)">{{getExceptName(index+1, item)}}</el-link>
                                        </el-col>
                                        <el-col :span="4" >
                                            <el-button type="primary"  size="mini" @click="onBottom(item)" >置底</el-button>
                                        </el-col>
                                        <el-col :span="4">
                                            <el-button type="danger" icon='edit' size="mini" @click="onDeleteScanFile(item)" >删除</el-button>
                                        </el-col>
                                    </el-row>

                                </el-collapse-item>
                                <el-collapse-item name="6">
                                    <template slot="title">
                                        <span class="collapse-title">客观题填涂异常</span>
                                        <span v-if="wrongForm.totalCount > 0" class="collapse-title" style="color: red;" >({{wrongForm.totalCount}})</span>
                                    </template>

                                    <el-row class="data-row" v-if="wrongOcrList && wrongOcrList.length > 0" v-for="(item, index) in wrongOcrList">
                                        <el-col :span="15" :offset="1" class="student-no-lost">
                                            <el-link type="primary" @click="openAnswerNotClear(item)">{{item.student_name + '(' + item.student_no + ")"}}</el-link>
                                        </el-col>
                                        <el-col :span="4" >
                                            <el-button type="primary"  size="mini" @click="onOcrBottom(item)" >置底</el-button>
                                        </el-col>
                                        <el-col :span="4">
                                            <el-button type="danger" icon='edit' size="mini" @click="onDeleteOcr(item)" >删除</el-button>
                                        </el-col>
                                    </el-row>

                                </el-collapse-item>
                            </el-collapse>
                        </el-col>
                    </el-row>

                </div>

            </el-col>
        </el-row>


    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import { saveAs } from 'file-saver';
    import * as simpleApi from "request/simple";
    import studentNoLost from "./studentNoLost";
    import examImageEditor from "./examImageEditor";
    import answerNotClear from "./answerNotClear";
    import examExcept from "./examExcept";
    import XEUtils from "xe-utils";

    export default {
        name: 'exceptPage',
        data(){
            return {
                tableData: [], tableHeight:0, examList: [],
                examCount: {ocring: 0, except: 0, ocred: 0},
                loading:true, isShow:false, isShowReOcrSY: false, activeName: '1',
                selStudentNoLost: null, selStudentNoLostY1: null, selStudentNoLostY9: null,  selAnswerNotClear: null, selExamExcept: null,
                loseNoList: [], loseNoY1List: [], wrongOcrList: [], exceptList: [], page1NoFoundList: [], duplicList: [],
                leftStyle: null, examId: null,
                loseNoForm: {totalCount: 0, examId: null, schoolId: null},
                loseNoY1Form: {totalCount: 0, examId: null, schoolId: null},
                exceptForm: {totalCount: 0, examId: null, schoolId: null},
                page1NoFoundForm: {totalCount: 0, examId: null, schoolId: null},
                duplicForm: {totalCount: 0, examId: null, schoolId: null},
                wrongForm: {totalCount: 0, examId: null, schoolId: null},
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, examId: null, schoolId: null},
            }
        },
        components:{
            studentNoLost, examImageEditor, answerNotClear, examExcept
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
        created() {
            let myHeight = document.documentElement.clientHeight ;
            console.log('myHeight:', myHeight);
            this.leftStyle = {height: (myHeight-200) + 'px'};
        },
      	mounted() {
            this.loadExamList();
	   },
        methods: {
            getExamName(index, item){
                if(item.except_info != null && item.except_info != ''){
                    let stuInfo = JSON.parse(item.except_info);
                    if("student_no" in stuInfo) return index + "." + stuInfo["student_no"] + "(" + item.seq + ")";
                    if("studentNo" in stuInfo) return index + "." + stuInfo["studentNo"] + "(" + item.seq + ")";
                }
                return index + "." + mUtils.strLeft(item.exam_name, 15) + "(" + item.seq + ")";
            },
            getExceptName(index, item){
                let status = item.status;
                if(status == "SY") return index + "." + "缺少第一页(" + item.seq+")";
                if(status == "Y5") return index + "." + "找不到定位点(" + item.seq+")";
                if(status == "Y6") return index + "." + "切图异常(" + item.seq+")";
                if(status == "F1") return index + "." + "第一页已删除(" + item.seq+")";
                if(status == "Y7") return index + "." + "不是这张试卷？(" + item.seq+")";
                return index + "." + mUtils.strLeft(item.exam_name, 15);
            },
            examChange(v){
                this.setSelNull();
                this.loadScanCount();
                this.loadExceptStudentNotFoundList();
                this.loadExceptStudentLoseList();
                this.loadExceptExamList();
                this.loadExceptStudentRepeatList();
                this.loadPage1NoFoundList();
                this.loadWrongOcrList();
            },
            loadExamList(){

                let search = { licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'exam', methodName: '/exceptExamlist', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.examList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadScanCount(){

                let search = { licenceId: this.userInfo.licenceId, examId: this.examId};
                let param = { controllerName: 'exam', methodName: '/scanCount', param: search};
                return simpleApi.post(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data) {
                        this.examCount = {ocring: data.scaning_count, except: data.scan_count, ocred: data.scan_result_count};
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadExceptStudentNotFoundList(callback){

                let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.examId), status: 'Y1'};
                let param = { controllerName: 'scan/scanExcept', methodName: '/list', param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadExceptStudentNotFoundList", data)
                    if (data.code == 0) {

                        this.loseNoY1List = data.result;
                        this.loseNoY1Form.totalCount = data.totalCount;
                        if(callback) callback(data.result);
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadExceptStudentLoseList(callback){

                let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.examId), status: 'Y4'};
                let param = { controllerName: 'scan/scanExcept', methodName: '/list', param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadExceptStudentLoseList", data)
                    if (data.code == 0) {
                        this.loseNoList = data.result;
                        if(callback) callback(data.result);
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadExceptExamList(callback){

                let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.examId), inStatus: ['Y5', 'Y6', 'Y7']};
                let param = { controllerName: 'scan/scanExcept', methodName: '/list', param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadExceptExamList", data)
                    if (data.code == 0) {
                        this.exceptList = data.result;
                        if(callback) callback(data.result);
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadExceptStudentRepeatList(callback){

                let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.examId), status: 'Y9'};
                let param = { controllerName: 'scan/scanExcept', methodName: '/list', param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadExceptStudentRepeatList", data)
                    if (data.code == 0) {
                        this.duplicList = data.result;
                        this.duplicList = XEUtils.sortBy(this.duplicList, [item => {
                            if(item.except_info){
                                let ei = JSON.parse(item.except_info)
                                if("studentNo" in ei) return ei.studentNo;
                                if("student_no" in ei) return ei.student_no;
                            }
                        }]);
                        if(callback) callback(data.result);
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadPage1NoFoundList(callback){

                this.isShowReOcrSY = false;
                let search = { licenceId: this.userInfo.licenceId, examId: mUtils.searchText(this.examId), status: "SY"};
                let param = { controllerName: 'scan/scanExcept', methodName: '/list',  param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadPage1NoFoundList", data)
                    if (data.code == 0) {
                        let list = data.result;
                        if(list){
                            this.page1NoFoundList = list;
                            if(callback) callback(data.result);
                        }
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadWrongOcrList(callback){

                let search = { licenceId: this.userInfo.licenceId, status: 'FE', examId: mUtils.searchText(this.examId), orderBy: 'uddt ' };
                let param = { controllerName: 'exam/ocr', methodName: '/list', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadWrongOcrList", data)
                    if (data.code == 0) {
                        this.wrongOcrList = data.result;
                        this.wrongForm.totalCount = data.totalCount;
                        if(callback) callback(data.result);
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            nextStudentNoLost(){
                console.log('nextStudentNoLost', this.selStudentNoLost)
                let status = this.selStudentNoLost.status;
                if(status == 'Y1') this.loadExceptStudentNotFoundList(this.nextStudentCallback);
                if(status == 'Y4') this.loadExceptStudentLoseList(this.nextStudentCallback);
                if(status == 'Y9') this.loadExceptStudentRepeatList(this.nextStudentCallback);
                if(status == 'SY') this.loadPage1NoFoundList(this.nextStudentCallback);
                if(status == 'Y5' || status == 'Y6' || status == 'Y7') this.loadExceptExamList(this.nextStudentCallback);

            },
            nextStudentCallback(list){
                if(list && list.length > 0){
                    this.openStudentNoLost(list[0]);
                }else{
                    this.setSelNull();
                }
                // this.examChange();
            },
            nextExamExcept(){
                let that = this;
                this.loadExceptExamList( function (list) {
                    console.log("nextExamExcept", list)
                    if(list && list.length > 0){
                        that.openExamExcept(list[0]);
                    }else{
                        that.setSelNull();
                    }
                });
            },
            nextAnswerExcept(){
                let that = this;
                this.loadWrongOcrList( function (list) {
                    if(list && list.length > 0){
                        that.openAnswerNotClear(list[0]);
                    }else{
                        that.setSelNull();
                    }
                });
            },
            collapseChange(v){
                let examId = mUtils.searchText(this.examId);
                if(examId == null){
                    this.$message({message: '请选择考试！', type: 'warning'});
                    return;
                }
            },
            downloadImage(item) {
                saveAs(item.oss_full_name, "image.png");
            },
            setSelNull(){
                this.selAnswerNotClear = null;
                this.selStudentNoLost = null;
                this.selStudentNoLostY1 = null;
                this.selExamExcept = null;
                this.selStudentNoLostY9 = null;
            },
            openStudentNoLost(item){
                this.setSelNull();
                console.log("openStudentNoLost", item)
                this.selStudentNoLost = item;
            },

            openExamExcept(item){
                this.setSelNull();
                let url = item.oss_full_name;
                if(url == null || url == ''){
                    url = item.oss_cdn_name;
                }
                let studentNo = null;
                if(item.except_info){
                    try{
                        let o = JSON.parse(item.except_info)
                        studentNo = o["studentNo"]
                    }catch (e) {
                    }
                }
                console.log("openStudentNoLostY1", item)
                this.selExamExcept = {url: url, id: item.id, scanFileId: item.id, sameExamId: item.same_exam_id, examId:this.examId, batchId: item.batch_id, studentNo: studentNo, seq: item.seq, exceptType: item.status };
            },

            openAnswerNotClear(row){
                this.setSelNull();
                this.selAnswerNotClear = row;
            },

            onOcrBottom(item){
                let that = this;
                let param = { controllerName: 'exam/ocr', methodName: '/update', param: { id: item.id,}};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    if (code == 0) {
                        that.$message({ type: 'success', message: '设置成功!'});
                        that.loadWrongOcrList();
                    }
                });
            },
            onBottom(item){
                let that = this;
                let status = item.status;
                let param = { controllerName: 'scan/scanFile', methodName: '/updateLastTime', param: {id: item.id}};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    if (code == 0) {
                        that.$message({ type: 'success', message: '设置成功!'});
                        if(status == 'Y1') that.loadExceptStudentNotFoundList();
                        if(status == 'Y4') that.loadExceptStudentLoseList();
                        if(status == 'Y9') that.loadExceptStudentRepeatList();
                        if(status == 'SY') that.loadPage1NoFoundList();
                        if(status == 'Y5' || status == 'Y6' || status == 'Y7') that.loadExceptExamList();
                    }
                });
            },
            onDeleteScanFile(item){
                let that = this;

                let status = item.status;

                this.doDeleteScanFile(item, function () {
                    // that.examChange();
                    if(status == 'Y1') that.loadExceptStudentNotFoundList(that.nextStudentCallback);
                    if(status == 'Y4') that.loadExceptStudentLoseList(that.nextStudentCallback);
                    if(status == 'Y9') that.loadExceptStudentRepeatList(that.nextStudentCallback);
                    if(status == 'SY') that.loadPage1NoFoundList(that.nextStudentCallback);
                    if(status == 'Y5' || status == 'Y6' || status == 'Y7') that.loadExceptExamList(that.nextStudentCallback);
                });

            },
            doDeleteScanFile(item, callback){
                let that = this;
                this.$confirm('将删除此试卷?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {

                    let param = { controllerName: 'scan/scanExcept', methodName: '/deleteBySameExamId', param: { sameExamId: item.same_exam_id }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '删除成功!'});
                            if(callback) callback();
                        }
                    });
                }).catch(() => {

                });
            },
            onDeleteNoLose(row){
                let that = this;
                this.$confirm('将删除此填涂?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {
                    let param = { controllerName: 'exam/noLose', methodName: '/delete', param: { id: row.id }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '删除成功!'});
                            simpleApi.post({ controllerName: 'scan/scanFile', methodName: '/delete', param: { id: row.scan_file_id }}).then(({ result: { code, data}, sysCode}) =>  {
                                if (code == 0) {
                                }
                            });
                            that.loadNoLoseList();
                        }
                    });
                }).catch(() => {

                });
            },
            onDeleteOcr(item){
                let that = this;
                this.$confirm('将删除此填涂?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {

                    let param = { controllerName: 'exam/ocr', methodName: '/delete', param: { id: item.id }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '删除成功!'});
                            that.loadWrongOcrList();
                        }
                    });
                }).catch(() => {

                });
            },


        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .search_container{

    }
    .collapse-title{
        font-size: 16px;
        font-weight: bold;
        margin-left: 20px;
    }
    .left-part {
        padding: 10px;
        border: 1px solid rgba(203, 202, 202, 0.71);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .right-part {
        padding: 10px;
        border: 1px solid rgba(203, 202, 202, 0.71);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        .data-row{
            margin-bottom: 10px;
        }
        .student-no-lost {
            text-align: left;
        }
    }
    .btnRight{
        float: right;
        margin-right: 0px !important;
    }
    .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
    }
    .table_container{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
    }
    .el-dialog--small{
       width: 600px !important;
    }
    .pagination{
        text-align: left;
        margin-top: 10px;
    }

</style>


