<template>
  <div class="examExcept" >
    <el-row  class="answerHead">
      <el-col :span="24">
        <span>批次号：{{batchId}}</span>， <span>序号：{{seq}}</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div style="width: 100%; height: 100%; overflow:auto;">
          <img style="width: 100%; height: 100%" class="zoomImg" ref="imgContainer"
                    :src="url" @mousewheel="handleZoomImg"></img>
        </div>
      </el-col>
    </el-row>
    <el-row v-if="(exceptType == 'Y5' || exceptType == 'Y6' || exceptType == 'Y7') && uploadUrl != null">
      <el-col :span="24">
        <el-upload class="upload-demo" :action="uploadUrl" :headers="uploadHeader" :limit="2" :on-change="handleChange" :auto-upload="true">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            上传后系统会重新识别 <br/>
            只能上传png文件，且不超过2M
          </div>
        </el-upload>
      </el-col>
    </el-row>

    <studentBox v-if="exceptType == 'SY'" title="选择学生" :scanFileId="scanFileId" ref="exceptStudentBox" @ok="selectStudent" :exceptType="exceptType" :examId="examId" :searchText="searchForm.searchText" :id="scanFileId" :top="200" :left="300" :right="0"></studentBox>
  </div>

</template>

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  import XEUtils from 'xe-utils'
  import * as simpleApi from "request/simple";
  import * as mUtils from '@/utils/mUtils'
  import Pagination from "@/components/pagination";
  import store from "../../store";
  import studentBox from "./studentBox";

  export default {
    name: 'examExcept',
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
        userToken: state => state.user.token,
      }),
    },
    props: {
      info: {
        type: Object
      }
    },
    components:{
      Pagination, studentBox
    },
    watch: {
      info: {
        deep: true, immediate:true,
        handler(n, o){
          console.log(n)
          if(n){
            this.url = n.url;
            this.scanFileId = n.scanFileId;
            this.batchId = n.batchId;
            this.seq = n.seq;
            let myHost = process.env.NODE_ENV=='production'?window.g.PROD_HOST:window.g.DEV_HOST;
            this.uploadUrl = myHost + 'scan/scanFile/upload?id='+n.scanFileId
            this.uploadHeader["ke-Token"] = this.userToken;
            this.examId = n.examId;
            this.exceptType = n.exceptType;
            this.tableData = [];
            this.searchForm.searchText =  null;
            let that = this;
            if(this.exceptType == 'SY'){
              this.$nextTick(() => {
                that.$refs.exceptStudentBox.showDialog();
              });
            }
          }
        }
      },
    },
    data() {
      return {
        url: '', scanFileId: null, examId: null, exceptType: null, batchId: null, seq: null,
        uploadUrl: null,
        uploadHeader: {
          'Company-url': 'http://www.szpcyl.com',
          'X-Powered-By': 'raozq',
          'ke-Token': 'noLogin',
          'ke-Domain': 'WB',
          // 'Content-Type':'multipart/form-data'
        },
        theDialogVisible: true, loading:false, tableData: [],
        searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null},
      }
    },
    mounted() {
      document.getElementsByClassName('zoomImg')[0].addEventListener('DOMMouseScroll', this.handleZoomImg, true);

    },
    methods: {

      handleChange(file, fileList) {
        console.log(file, fileList);
        if(file && "success" == file.status){
          this.$message({ type: 'success', message: '上传成功!'});
          this.$emit('ok');
        }
      },
      // 滚轮放大缩小图片 方法
      handleZoomImg(evt) {
        evt = evt || window.event
        if (!evt) evt = window.event
        if (evt.stopPropagation) { // 这是取消冒泡,阻止浏览器滚动事件
          evt.stopPropagation()
        } else {
          evt.cancelBubble = true
        };
        if (evt.preventDefault) { // 这是取消默认行为，要弄清楚取消默认行为和冒泡不是一回事
          evt.preventDefault()
        } else {
          evt.returnValue = false
        };
        let delta = 0
        if (evt.wheelDelta) {
          delta = evt.wheelDelta / 120
          if (window.opera) delta = -delta
        } else if (evt.detail) {
          delta = -evt.detail / 3
        }
        let imgContainer =  this.$refs.imgContainer
        let width = imgContainer.offsetWidth
        let height = imgContainer.offsetHeight
        if (delta > 0) {
          imgContainer.style.width = `${width * 1.1}px`
          imgContainer.style.height = `${height * 1.1}px`
        } else if (delta < 0) {
          imgContainer.style.width = `${width * 0.9}px`
          imgContainer.style.height = `${height * 0.9}px`
        }
      },
      selectStudent(){
        this.$refs.exceptStudentBox.hideDialog();
        this.$emit('ok');
      },

    }
  }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

  .examExcept {

    .answerHead {
      margin-bottom: 10px;
      font-weight: bold;
      color: blue;
      span {
        font-size: 24px;
      }
    }

    .el-form-item__label {
      font-size: 16px;
      color: black;
    }

  }




</style>
