<template>
  <div class="studentNoLost" >
    <el-row v-for="(item, index) in scanList">
      <el-col :span="24">
        <span style="color: #F6F7FC;">{{scanFile.id}}, {{scanFile.seq}}, {{scanFile.same_exam_id}}</span>
        <span >批次号：{{scanFile.batch_id}}, 序号：{{scanFile.seq}}</span>
        <div style="width: 100%; height: 100%; overflow:auto;">
          <img style="width: 100%; height: 100%" class="zoomImg" :ref="'imgContainer'+index"
               :src="item.ossFullName" @mousewheel="handleZoomImg($event, 'imgContainer'+index)" />
        </div>
      </el-col>

    </el-row>

    <studentBox v-if="scanFile != null" title="选择学生" :scanFileId="scanFile.id" ref="studentBox" @ok="selectStudent" :exceptType="scanFile.status"
                :examId="scanFile.exam_id" :searchText="searchText" :sameExamId="scanFile.same_exam_id" :top="200" :left="300" :right="0"></studentBox>


  </div>

</template>

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  import XEUtils from 'xe-utils'
  import * as simpleApi from "request/simple";
  import * as mUtils from '@/utils/mUtils'
  import Pagination from "@/components/pagination";
  import studentBox from "./studentBox";
  export default {
    name: 'studentNoLost',
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
    },
    props: {
      info: {
        type: Object
      }
    },
    components:{
      Pagination, studentBox
    },
    watch: {
      info: {
        deep: true, immediate:true,
        handler(n, o){
          console.log(n)
          if(n){
            this.scanFile = n;
            this.loadExamPages();
          }
        }
      },
    },
    data() {
      return {
        scanFile: null, scanList: [], searchText: '',
        url: '', id: null, scanFileId: null, examId: null,
        theDialogVisible: true, loading:false, tableData: [],
        searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null},
      }
    },
    mounted() {

    },
    methods: {

      loadExamPages(){
        let that = this;
        console.log("loadExamPages")
        let search = { licenceId: this.userInfo.licenceId, sameExamId: this.scanFile.same_exam_id};
        let param = { controllerName: 'scan/scanExcept', methodName: '/getBySameExamId',  param: search};
        return simpleApi.list(param).then(({result: {code, data}}) =>  {
          console.log("loadExamPages", data)
          if (data) {
            if(data){
              that.scanList = XEUtils.sortBy(data, 'seq');
              for (let i=0; i<data.length; i++){
                let d = data[i];
                if("student_no" in d) {
                  that.searchText = d.student_no;
                }else if("studentNo" in d){
                  that.searchText = d.studentNo;
                }else{
                  let except_info = null;
                  if("except_info" in d){
                    except_info = d.except_info;
                  }if("exceptInfo" in d){
                    except_info = d.exceptInfo;
                  }
                  if(except_info != null){

                    try{
                      let jo = JSON.parse(except_info);
                      if("student_no" in jo) that.searchText = jo.student_no;
                      if("studentNo" in jo) that.searchText = jo.studentNo;
                    }catch (e) {
                      console.log("loadExamPages", e)
                    }
                  }
                }
              }
            }
            that.$nextTick(() => {
              that.$refs.studentBox.showDialog();
              // document.getElementsByClassName('zoomImg')[0].addEventListener('DOMMouseScroll', that.handleZoomImg, true)
            });

          }
        }).catch((error) => {
          console.log("error")
        });
      },

      selectStudent(){
        this.$refs.studentBox.hideDialog();
        this.$emit('ok');
      },
      // 滚轮放大缩小图片 方法
      handleZoomImg(evt, imgName) {
        evt = evt || window.event
        if (!evt) evt = window.event
        if (evt.stopPropagation) { // 这是取消冒泡,阻止浏览器滚动事件
          evt.stopPropagation()
        } else {
          evt.cancelBubble = true
        };
        if (evt.preventDefault) { // 这是取消默认行为，要弄清楚取消默认行为和冒泡不是一回事
          evt.preventDefault()
        } else {
          evt.returnValue = false
        };
        let delta = 0
        if (evt.wheelDelta) {
          delta = evt.wheelDelta / 120
          if (window.opera) delta = -delta
        } else if (evt.detail) {
          delta = -evt.detail / 3
        }
        let myImgContainer =  this.$refs[imgName]
        if(myImgContainer && myImgContainer.length > 0){
          let imgContainer = myImgContainer[0];
          // console.log("imgContainer", imgContainer)
          let width = imgContainer.offsetWidth
          let height = imgContainer.offsetHeight
          if (delta > 0) {
            imgContainer.style.width = `${width * 1.1}px`
            imgContainer.style.height = `${height * 1.1}px`
          } else if (delta < 0) {
            imgContainer.style.width = `${width * 0.9}px`
            imgContainer.style.height = `${height * 0.9}px`
          }
        }
      },
    }
  }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

  .studentNoLost {

    .el-form-item__label {
      font-size: 16px;
      color: black;
    }

  }




</style>
